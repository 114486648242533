import React from 'react';
import './submit-loader.css';

const SubmitLoader = ({msg}) => (
    <div className="submit-loader-overlay">
        <div className="submit-loader-body">
            <p class="loading">Please wait!<br />{msg}</p>
        </div>
    </div>
);

export default SubmitLoader;