// CSS
import 'swiper/dist/css/swiper.min.css';
import 'react-rater/lib/react-rater.css';
import './index.css';

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Routes from './containers';

import { Provider } from 'react-redux';
import { store } from "./store";

const Index = () => {
  return(
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render( <Index /> , document.getElementById("index"));