import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Ionicon from 'react-ionicons';
// import logo from '../../assets/img/logo.png';
import {BASE_URL_REACT_QUIZY_LOGO} from '../http-api';

const HeaderWithBackButton = ({ history, openSearchBox }) => (
    <Fragment>
        <div id="toolbar" className="bluedark toporange botorange">
            {/* <div className="open-left">
                <a onClick={()=>history.goBack()} className="link back">
                    <Ionicon icon="md-arrow-back" fontSize="1em" color="#fff" />
                </a>
            </div> */}
            <div className="logo m-0">
                <Link to='/'><img src={BASE_URL_REACT_QUIZY_LOGO} alt="" style={{height: '42px'}}/></Link>
            </div>
            {/* <div className="open-right" data-activates="slide-out-right">
                <a onClick={()=>openSearchBox()} seach="true" className="ion-head">
                    <Ionicon icon="md-search" fontSize="1em" color="#fff" />
                </a>
            </div> */}
        </div>
        <div className="clearfix"></div>
    </Fragment>
);

export default withRouter(HeaderWithBackButton);