import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import FreeQuizDetails from './free-play/quiz-details';
import Result from './quiz/result';
import Results from './quiz/results';
const Home = React.lazy(() => import('./home/Home'));
import './index.css';
// import LetsPlay from './LetsPlay';
// import AllTimeLeaderboard from './all-time-leaderboard/AllTimeLeaderboard';
// import QuizDetails from './quiz/quiz-details';
// import ChangePassword from './change-password/ChangePassword';
// import PrivateRoute from '../components/private-route';
// import DisableAuthRoute from '../components/disable-auth-route';
// import FAQ from './FAQ';
// import Rewards from './reward/rewards';
// import RewardDetails from './reward/reward-details';
// import Profile from './profiles';
// import Language from './Language';
// import Logout from './Logout';
// import Unsubcribe from './Unsubcribe';
// import PlayQuizHome from './play-quiz-home/play-quiz-home';
// const FreeQuizPlay = React.lazy(() => import('./free-quiz-play/FreeQuizPlay'));
// const LoginRegistration = React.lazy(() => import('./login-registrtion/LoginRegistration'));


const Routes = ({ location }) => (
  <React.Suspense fallback={() => <div>Loading...</div>}>
    <Switch>
      {/* <DisableAuthRoute path='/login-or-register' component={LoginRegistration} />
      <Route path='/change-password' component={ChangePassword}/>
      <Route path='/lets-play' component={LetsPlay} />
      <Route path="/free-quiz-play" component={FreeQuizPlay} />
      <Route path='/quiz/free' component={FreeQuizDetails} />
      <PrivateRoute path='/leaderboard' component={AllTimeLeaderboard} />
      <PrivateRoute path='/lang' component={Language} />
      <PrivateRoute exact path='/rewards/:playType' component={Rewards} />
      <PrivateRoute path='/rewards/details/:id' component={RewardDetails} />
      <PrivateRoute path='/profile' component={Profile} />
      <PrivateRoute path='/quiz-details/:id' component={QuizDetails} />
      <PrivateRoute path='/result/:playType/:id' component={Result} />
      <PrivateRoute path='/faq' component={FAQ} />
      <Route path="/unsubcribe" component={Unsubcribe} />
      <Route path="/logout" component={Logout} /> */}
      <Route path='/result/:id' component={Result} />
      <Route path='/results/:id' component={Results} />
      <Route exact path='/' component={Home} />
      <Route  path='/:id' component={FreeQuizDetails} />
      <Redirect to="/" />
    </Switch>
  </React.Suspense >
);

export default withRouter(Routes);