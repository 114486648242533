import React, { useEffect } from "react";
import { post } from "../../http-api/index";
import { userDetails as loggedInUserDetails } from "../../helper/authentication";
import BannerSlider from "../../containers/home/banner-slider/BannerSliderSmsUrl";
import { useState } from "react";
import { AllLeaderBoardSimmer } from "../../content-loader/index";
import { withRouter } from "react-router-dom";
import {Modal,Button} from 'react-bootstrap';
import { userDetails } from '../../helper/authentication';
import './quiz-home.css';

const PlayQuizHome = (props) => {
  const [loaing, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState(null);
  const [msisdn,setMsisdn]= useState('');
  const [error,setError] = useState({});

  useEffect(() => {
    const smsUrlKey = window.location.search.substring(1);
    setLoading(true);
    var payload = new FormData();
    payload.append("key", smsUrlKey);

    post("smsLogin", payload)
      .then((res) => {
        if (res.data && res.data.success === 1) {
          

          if(res.data.menber_details.msisdn_number){
            
            localStorage.setItem(
              "userDetails",
              JSON.stringify(res.data.menber_details)
            );

            setUser(res.data.menber_details)
            
          }else{

            localStorage.setItem(
              "userDetails",
              JSON.stringify(res.data.menber_details)
            );

            setModal(true);
          }
          setLoading(false);
        } else if (res.data.success === 0 && res.data.error === 1) {
          setLoading(false);
          props.history.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const msisdnSubmit =()=>{

        const error = {};
        if(msisdn.trim().length===0){
          error.msisdn = 'Msisdn can not be empty';
          setError(error)
        }
        if(Object.keys(error).length===0){
          
          const payload = new FormData();
          payload.append('user_id', userDetails().user_id);
          payload.append('msisdn', msisdn);

            post('getRealMsisdn', payload)
            .then( res => {
                if (res.data.success == 1) {

                    const newUser = Object.assign({},user,{msisdn_number:res.data.msisdn});
                    setUser(newUser);
                    localStorage.setItem(
                      "userDetails",
                      JSON.stringify(newUser)
                    );
                    setModal(false);

                }
                if (res.data.success === 0) {
                    error.msisdn = res.data.message
                    setError(error)
                }
            })
            .catch(error=> {
                console.log(error);
            });
        }
        

    }

  return (
    <React.Fragment>
      {loaing ? (
        <AllLeaderBoardSimmer />
      ) : (
        <div>
          {loggedInUserDetails() ? (
            user &&
            <div style={{ color: "#333", padding: "3%", fontSize: "large",position: "absolute",
    marginTop: "173px", textAlign: "center",marginLeft: "73px" }}>
              Hello <br />User {user.msisdn_number.slice(0,8)+"XXXX"}
            </div>

          ) : (
            <div>no user found</div>
          )}
          <BannerSlider />
        </div>


      )}

      <Modal className='msisdnPopup'show={modal} >
                        
        <Modal.Body>
        <span className="modalText">Selamat Datang di QUIZY</span><br /><br />
        <span className="modalSubText">Masukan No. HP Kamu untuk mulai <br /> bermain layanan Quizy</span>
        <input type="text" onChange={event=>setMsisdn(event.target.value )} className={error.hasOwnProperty('msisdn') ? 'errormsg' : ''}  />
        <i class="fa fa-mobile famobile" aria-hidden="true"></i>
        {error.hasOwnProperty("msisdn") && 
         <div style={{color:'red'}}>{error.msisdn}</div>
        }
        </Modal.Body>
        <Modal.Footer >
          
          <Button variant="primary" onClick={msisdnSubmit}>
            Submit
          </Button>
        </Modal.Footer>

      </Modal>


    </React.Fragment>
  );
};
export default withRouter(PlayQuizHome);
