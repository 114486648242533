import React, { Component, Fragment } from "react";
import { Link, withRouter } from 'react-router-dom';
import { stack as Menu } from "react-burger-menu";
import Account from './account';
import {selectedLanguage} from '../../helper/authentication';
class SideBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuOpen: false
        }
    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange = (state) => {
        this.setState({
            menuOpen: state.isOpen
        })
    }

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu = () => {
        this.setState({
            menuOpen: false
        })
    }
    
    toggleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/');
    }
    render(){
        const language = { "en": "English", "id": "Indonesia", "ms": "Malaysia", "nl": "Deutch" };
        const date = new Date();
        return (
            <Menu {...this.props}
                isOpen={this.state.menuOpen} 
                onStateChange={(state) => this.handleStateChange(state)}
            >
                <Account closeMenu={this.closeMenu} />
                <a className="menu-item menu-divider">
                    Menu
                    <span style={{
                        float: 'right',
                        background: '#5302eb',
                        padding: '0px 10px',
                        lineHeight: '20px',
                        marginTop: '7px',
                        borderRadius: '2px'
                    }}>Mega</span>
                </a>
                <Link onClick={() => this.closeMenu()} to={`/homepage/${this.props.match.params.playType}`} className="menu-item indivdual-item-menu">
                    <i className="fa fa-home"></i><div className="menu-item-div">Home</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to={`/rewards/${this.props.match.params.playType}`} className="menu-item indivdual-item-menu">
                    <i className="fa fa-trophy"></i><div className="menu-item-div">Rewards</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to="/leaderboard" className="menu-item indivdual-item-menu">
                    <i className="fa fa-trophy"></i><div className="menu-item-div">Leader Board</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to={{pathname:"/lang", state: { from: this.props.location.pathname} }} className="menu-item indivdual-item-menu">
                    <i className="fa fa-globe"></i><div className="menu-item-div">Language</div>
                    <div style={{float: 'right', marginRight: '40px'}}>{language[selectedLanguage()]}</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to="/logout" className="menu-item indivdual-item-menu">
                    <i className="fa fa-sign-out-alt"></i><div className="menu-item-div">Logout</div>
                </Link>
                <a className="menu-item menu-divider"></a>
                <Link onClick={() => this.closeMenu()} to="/faq" className="menu-item indivdual-item-menu">
                    <i className="fa fa-question-circle"></i><div className="menu-item-div">FAQ</div>
                </Link>
            </Menu>
        );
    }
};

export default withRouter(SideBar);
