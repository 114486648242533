import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import TemplateWithSlideBar from '../../template/template-with-slide-bar';
import Avatar from 'react-avatar'; 
import { post } from '../../http-api';
import { loggedInUserDetails } from '../../helper/authentication';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './result.css';
import {BASE_URL_PROFILE} from '../../http-api';
import shareIcon from '../../assets/share.png';
import Share from '../../components/share';
import { NoDataFound } from '../no-data-found';

class Result extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: {},
            noDataFound: false,
            shareComponent: false
        }
    }
    openShareComponent = () => {
      this.setState({shareComponent: true});
      document.getElementById('pageContent').classList.add("overflow-hidden");
    }
    closeShareComponent = () => {
      this.setState({shareComponent: false});
      document.getElementById('pageContent').classList.remove("overflow-hidden");
    }
    componentDidMount() {
        this.getResultById(this.props.match.params.id);
    }
    getResultById = (id) => {
        
        console.log(this.props.match.params.id)
        const user_id=this.props.match.params.id;
        const payload = new FormData();
        payload.append('user_id', user_id);
        post('resultDetail', payload)
        .then(res => {
            if(res.data.success) {
                let { result } = res.data;
                console.log(result[0]);
                this.setState({ result:result[0], noDataFound: false });
            }
            if (res.data.error) {
                this.setState({ noDataFound: true });
            }
        })
        .catch(err => {
            this.setState({ noDataFound: true });
            throw new Error('Could not fetch products. Try again later.');
        });
    }
    
    timeFormat = (time) => {
        const min = Math.floor(time / 60);
        const sec = time - min*60;
        return `  ${min}m ${(sec).toFixed(3)}s`;
    }

    render() {
        const { result, shareComponent, noDataFound } = this.state;
        console.log(this.props);

        return(
            <Fragment>
                <TemplateWithSlideBar>
                    <div className="block-title">
                        <h3>Quiz Result</h3>
                    </div>
                    {noDataFound? <NoDataFound />:
                    <Fragment>
                    {/* <div class="share-result"> 
                        <div className="share-icon-result" onClick={()=>this.openShareComponent()}>
                            <span>Share</span>
                            <img src={shareIcon} />
                        </div>
                    </div> */}
                    <div className="pd-15">
                        <div className="quiz-info">
                            <h5>
                                Quiz: <span style={{textTransform: 'capitalize'}}>{result.category_title}</span>
                                <span className="pull-right"><i className="fa fa-clock"></i> {this.timeFormat(result.total_time)}</span>
                            </h5>
                        </div>
                        <div className="set"> 
                            <div className="set-img">
                                {result && <Avatar
                                    round={true}         
                                    size="140" 
                                    src={result.image ? `${BASE_URL_PROFILE+result.image}` : null}
                                    name={'Quizy'}
                                />}
                            </div>
                            <div className="set-bar" style={{marginRight: '16px'}}>
                                <div className="set-extra">
                                    <h5 className="desc1">Right Answer: <span>{result.total_right}</span></h5>
                                </div>
                                <div className="set-extra">
                                    <h5 className="desc2">Wrong Answer: <span>{result.total_wrong}</span></h5>
                                </div>
                                
                                <div className="set-extra">
                                    <h5 className="desc1">Accurate: <span>{(parseFloat(result.total_accurate)).toFixed(2)}%</span></h5>
                                </div> 
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            marginTop: '39px',
                            background: '#128bb4',
                            padding: '15px',
                            borderRadius: '10px',
                            color: '#fff',
                            letterSpacing: '0.5px',
                            fontSize: '20px'
                        }}> 
                            {/* Total Points: {parseInt(result.right)} X {result.total_point/result.right} = <span>&nbsp;{parseInt(result.total_point)}</span> */}
                            Total Score:<span>&nbsp;{parseInt(result.total_score)}</span>
                        </div>
                        </div>
                         <div className="pd-15" >
                        <div className="quiz-info" style={{padding: '2px 15px'}}></div></div>
                        <div className="clearfix"></div>
                        <div class="pd-15 text-center">
                            <Link to={`/`} class="btn btn-primary btn-round">Back to Home</Link>
                        </div>
                        <div class="clearfix"></div></Fragment>}
                </TemplateWithSlideBar>
                {/* {shareComponent && <Share closeShareComponent={this.closeShareComponent}/>} */}
            </Fragment>
        );
    }
};


export default withRouter(Result);

const ResultDetails = (props) => {
    return(<h1>Result Details</h1>);
};