import React, { Component, Fragment } from 'react';
import SvgProgressBar from './svg-progress-bar';
import tick from '../assets/tick.mp3';

class Timer extends Component {
    timer = null;
    interval = null;
    audio = new Audio(tick);

    constructor(props) {
        super(props);
        this.state = {
            time: this.props.timeduration
        }
    }

    componentWillReceiveProps(newProps) {
        clearInterval(this.interval);
        clearInterval(this.timer);
        if (newProps.stopTimer) {
            this.pauseAudio();
        } else {
            this.setState({ time: newProps.start });
            this.cownDown();
        }
    }

    componentDidMount() {
        this.cownDown();
    }

    componentWillUnmount() {
        this.audio = null;
        clearInterval(this.timer);
    }

    cownDown = () => {
        // var ms = 26000;
        var ms = parseInt(this.props.timeduration) * 1000;
        var startTime = Date.now();
        this.interval = setInterval(() => {
            var elapsedTime = startTime - Date.now();
            elapsedTime = ms + elapsedTime;
            console.log({ elapsedTime })
            var newtime = (elapsedTime / 1000).toFixed(3);
            if (newtime <= 0.001) {
                clearInterval(this.interval);
                this.props.onTimeUp('');
            } else {
                const { time } = this.state;
                if (Math.floor(time) !== Math.floor(newtime))
                    this.playAudio();
                console.log({ newtime })
                this.setState({ time: newtime });
            }
        }, 100);
    }

    playAudio = () => {
        if (this.audio && !document.hidden)
            this.audio.play();
    }

    pauseAudio = () => {
        if (this.audio)
            this.audio.pause();
    }

    render() {
        const { time } = this.state;
        console.log({ time })
        return (
            <Fragment>
                <div id="timer"></div>
                <div class="circle-gr" style={{ alignSelf: 'flex-end', marginLeft: 'auto', marginTop: '-15px', display: 'none' }}>
                    <strong id="Progress1" class="time-number">{15 - time}</strong>
                </div>
                <SvgProgressBar cowndown={Math.floor(time)} />
            </Fragment>
        );
    }
};

export default Timer;