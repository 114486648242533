import React, { Component } from 'react';
import Header from '../components/header/header';
import "../components/header/sidebar.css";
import SideBarPremium from "../components/header/sidebarPremium";
import SideBarMega from "../components/header/sidebarMega";
import Search from './search';
import { userDetails } from '../helper/authentication';

class TemplateWithSlideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSearchBox: false
        }
    }
    openSearchBox = () => {
        this.setState({openSearchBox: true});
    }
    closeSearchBox = () => {
        this.setState({openSearchBox: false});
    }
    render() {
        const {openSearchBox} = this.state; 
        return( 
            <React.Fragment id="outerContainer">
                <Header openSearchBox={this.openSearchBox}/>
                {/* {Boolean(userDetails().playtype == 'Premium Play') &&
                <SideBarPremium 
                    pageWrapId={"pageContent"} 
                    outerContainerId={"outerContainer"}
                />}
                {Boolean(userDetails().playtype == 'Mega Play') &&
                <SideBarMega 
                    pageWrapId={"pageContent"} 
                    outerContainerId={"outerContainer"} 
                />} */}
                {/* <Search open={openSearchBox} closeSearchBox={this.closeSearchBox} /> */}
                <div id="pageContent" className="page-content" style={{height: '100vh', overflow: 'auto', background: '#ecf0f1'}}>
                    {this.props.children}
                </div> 
            </React.Fragment> 
        );
    }
};
export default TemplateWithSlideBar;