import { combineReducers } from "redux";
import { quizReducers }  from './quizReducer';
import { bannerReducecer } from './bannerReducer';
import { questionReducecer } from './questionReducer';
import { answerReducecer } from './answerReducer';
import { faqReducecer } from './faqReducer';
import { privacyPolicyReducecer } from './privacyPolicyReducer';
import { termServiceReducecer } from './termServiceReducer';
import { rewardsReducers } from './redwardsReducer';
import { searchReducers } from './searchReducer';

export const allReducers = combineReducers({
    quiz: quizReducers,
    search: searchReducers,
    banner: bannerReducecer,
    question: questionReducecer,
    answer: answerReducecer,
    faq: faqReducecer,
    privacyPolicy: privacyPolicyReducecer,
    termServiceReducecer: termServiceReducecer,
    rewards: rewardsReducers
});
