import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { userDetails as loggedInUserDetails } from "../../helper/authentication";
import QuizDetailsTemplate from "../../template/template-with-goback-button";
import SubmitLoader from "./submit-loader";
import { post, post_loggedInUser } from "../../http-api";

import Swal from "sweetalert2";
import PlayQuizeHome from "../play-quiz-home/play-quiz-home";
class QuizDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quizPreparing: false,
      categoryDetails: {},
      noDataFound: false,
    };
  }


  playQuiz = (quiz) => {
    this.setState({ quizPreparing: true });
    const playType = this.props.match.params.playType;
    const payload = new FormData();;
    post_loggedInUser("questionBySchedule", payload)
      .then((res) => {
        this.setState({ quizPreparing: false });
        if (res.data.success) {
          let { questionlist,timeduration } = res.data;
          this.props.setQuestionList(questionlist,timeduration);
        }
      })
      .catch((err) => {
        throw new Error("Could not fetch products. Try again later.");
      });
    
  };
  getDetailsById = (id) => {
    const playType = this.props.match.params.playType;
    post("detailsfordemo")
      .then((res) => {
        if (res.data.success) {
          let { category_details } = res.data;
          this.setState({
            categoryDetails: category_details,
            noDataFound: false,
          });
        }
        if (res.data.error) {
          this.setState({ noDataFound: true });
        }
      })
      .catch((err) => {
        throw new Error("Could not fetch products. Try again later.");
      });
  };
  render() {
    const { categoryDetails, quizPreparing, noDataFound } = this.state;

    return (
      <Fragment>
        <QuizDetailsTemplate>
          <PlayQuizeHome />
          
          <div className="quizStartBlock" >

           <div className="letsPlay">LET'S PLAY!</div>
           <div className="letsPlayText">
           Quiz seru harian, mainkan sekarang dan<br />
           kumpulkan poinmu <br />
            Banyak hadiah menarik menanti !

           </div>
            <button className="pQuiz" onClick={() => this.playQuiz(this.props.match.params.id)} >
              PLAY QUIZ
            </button>
          </div>
        </QuizDetailsTemplate>
        {quizPreparing && <SubmitLoader msg="while your Quiz is prepared" />}
      </Fragment>
    );
  }
}
export default withRouter(QuizDetails);
