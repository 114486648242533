import React from 'react';
import {Link} from 'react-router-dom';
import Swiper from 'react-id-swiper';
import { post, BASE_URL_IMAGE } from '../../../http-api';
import { QuizBannerLoader } from '../../../content-loader';
import './BannerSlider.css';

const BannerSliderSmsUrl = () => {
    const [banner, setBanner] = React.useState([]);
    React.useEffect(() => {
        getBanner();
    }, [])
    const getBanner = () => {
        post('allBanner').then(res => {
            if (res.data.success) {
                setBanner(res.data.banner_details);
            }
        }).catch(err => {
            throw new Error('Could not fetch products. Try again later.');
        });
    }
    const params = {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: false,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        }
    };
    return (
        <React.Fragment>
            {banner.length ? <Swiper {...params}>
                {banner.map((data, key) => (
                    <div key={key} className="banner-container1" style={{paddingTop:'-50px'}}>
                            <img src={BASE_URL_IMAGE + data.banner_image} width="100%" height="80%" />
                       
                    </div>
                ))}
            </Swiper> : <QuizBannerLoader />}
        </React.Fragment>
    );
};

export default BannerSliderSmsUrl;