import React from 'react';
import './svg-progress-bar.css';

const SvgProgressBar = (props) => {
    const pct = ((parseInt(props.cowndown)) / 24) * Math.PI * (20 * 2);
    console.log(props.cowndown)
    return(
        <div id="cont" data-pct={props.cowndown}>
            <svg className="timer" width="50" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle className="circle" r="20" cx="25" cy="25" fill="transparent"  strokeDasharray="125.66370614359172" strokeDashoffset="0"
                    style={ props.cowndown > 5 ? {stroke: '#32CD32'} : {stroke: '#FF0000'} }
                ></circle>
                <circle className="circle" r="20" cx="25" fill="transparent" cy="25"  strokeDasharray="125.66370614359172" strokeDashoffset={pct}
                    style={ props.cowndown === 0 ? {stroke: '#FF0000'} : {stroke: '#d6d6d6'} }
                ></circle>
            </svg> 
        </div>
    );
};

export default SvgProgressBar;