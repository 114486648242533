import {
    FETCH_PRODUCTS,
    FETCH_LATEST_QUIZ,
    FETCH_BANNER_DETAILS,
    FETCH_QUESTION_LIST,
    SELECTED_QUESTION,
    ADD_PLAYED_QUIZ,
    FETCH_ANSWER_LIST,
    FETCH_PRIVACY_POLICY,
    FETCH_FAQ,
    FETCH_TERM_SERVICE,
    RESET_PLAYED_QUIZ,
    FETCH_ALL_REWARDS,
    FETCH_TOP_REWARDS,
    FETCH_MY_REWARDS,
    FETCH_QUIZ_HISTORY,
    FETCH_REWARDS_BANNER,
    SET_SEARCH_KEY,
    SET_SEARCH_RESULT,
    UPDATE_MY_REWARDS
} from "../types";

// Add Popular Quiz to Store
export const fetchPopularQuiz = (payload) => ({
    type: FETCH_PRODUCTS,
    payload: payload
});

// Add Latest Quiz to Store
export const fetchLatestQuiz = (payload) => ({
    type: FETCH_LATEST_QUIZ,
    payload: payload
});

// Add Question to Store By Fatching Using Category Id 
export const fetchQuestionByCatId = (payload) => ({
    type: FETCH_QUESTION_LIST,
    payload: payload
});

// Add Quiz History to Store
export const setQuizHistory = (payload) => ({
    type: FETCH_QUIZ_HISTORY,
    payload: payload
});

// Set Search Key to Store
export const setSearchKey = (payload) => ({
    type: SET_SEARCH_KEY,
    payload: payload
});

// Add Search Result To Store
export const setSearchResult = (payload) => ({
    type: SET_SEARCH_RESULT,
    payload: payload
});

// Add All Rewards to Store
export const fetchAllRewards = (payload) => ({
    type: FETCH_ALL_REWARDS,
    payload: payload
});

// Add Top Rewards to Store
export const fetchTopRewards = (payload) => ({
    type: FETCH_TOP_REWARDS,
    payload: payload
});

// Add Rewards Banner to Store
export const fetchRewardsBanner = (payload) => ({
    type: FETCH_REWARDS_BANNER,
    payload: payload
});

// Add User Rewards To Store
export const setMyRewards = (payload) => ({
    type: FETCH_MY_REWARDS,
    payload: payload
});

// Update My Rewords To Store
export const updateMyRewards = (payload) => ({
    type: UPDATE_MY_REWARDS,
    payload: payload
});

// Add Selected Question To Store
export const selectQuestion = (index) => dispatch => {
    return dispatch({
        type: SELECTED_QUESTION,
        payload: index
    });
}

// Set Question To Store
export const playedQuiz = (quiz) => dispatch => {
    return dispatch({
        type: ADD_PLAYED_QUIZ,
        payload: quiz
    });
}

// Reset PlayQuiz Store
export const resetPlayedQuiz = () => dispatch => {
    return dispatch({
        type: RESET_PLAYED_QUIZ
    });
}

// Set Banner Details To Store
export const fetchBannerDetails = (payload) => ({
    type: FETCH_BANNER_DETAILS,
    payload: payload
});

// Set FAQ to Store
export const fetchFaqDetails = (payload) => ({
    type: FETCH_FAQ,
    payload: payload
});