export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_LATEST_QUIZ = "FETCH_LATEST_QUIZ";
export const FETCH_BANNER_DETAILS = "FETCH_BANNER_DETAILS";
export const FETCH_QUESTION_LIST = "FETCH_QUESTION_LIST";
export const SELECTED_QUESTION = "SELECTED_QUESTION";
export const ADD_PLAYED_QUIZ = "ADD_PLAYED_QUIZ";
export const FETCH_ANSWER_LIST = "FETCH_ANSWER_LIST";
export const FETCH_PRIVACY_POLICY = "FETCH_PRIVACY_POLICY";
export const FETCH_FAQ = "FETCH_FAQ";
export const FETCH_TERM_SERVICE = "FETCH_TERM_SERVICE";
export const RESET_PLAYED_QUIZ = "RESET_PLAYED_QUIZ";
export const FETCH_ALL_REWARDS = "FETCH_ALL_REWARDS";
export const FETCH_TOP_REWARDS = "FETCH_TOP_REWARDS";
export const FETCH_MY_REWARDS = "FETCH_MY_REWARDS";
export const FETCH_QUIZ_HISTORY = "FETCH_QUIZ_HISTORY";
export const FETCH_REWARDS_BANNER = "FETCH_REWARDS_BANNER";
export const SET_SEARCH_KEY = "SET_SEARCH_KEY";
export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const UPDATE_MY_REWARDS = "UPDATE_MY_REWARDS";