import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { userDetails } from "../helper/authentication";
import { post } from '../http-api'; 
import Swal from 'sweetalert2';
class DisableBackButton extends Component {
    constructor(props) {
        super(props);
        if (window.performance) {
            if (window.performance.navigation.type == 1) {
                // window.location.href="/";
            }
        }
    }
    componentWillMount() {
       history.pushState(null, null, this.props.location.href);
       const _self = this;
       window.onpopstate = function (event) {
           history.go(1);
           console.log('exit quiz');
           Swal({
               title: 'Are you sure? For this action you will be deactivated and go to Homepage.',
               type: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Quit',
               cancelButtonText: 'Continue'
           }).then((result) => {
               if(result.value){
                // console.log({result});
                const payload = new FormData();
                payload.append('user_id', userDetails().user_id);
                payload.append('msisdn', userDetails().msisdn);
                post('removeSmsToken',payload)
                .then(res=>{
                    if(res.data.success){
                        localStorage.removeItem('userDetails');
                        _self.props.history.push('/')
                    }
                    else{
                    }
                    
                })
               }
               else{
               }
               
            
           })
       };
    }
    componentWillUnmount() {
        window.onpopstate = null;
    }
    render() {
        return(''); 
    }
};
export default withRouter(DisableBackButton);