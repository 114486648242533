import React, { Component, Fragment } from "react";
import { Link, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { stack as Menu } from "react-burger-menu";
import Account from './account';
import {selectedLanguage} from '../../helper/authentication';
import { post_loggedInUser } from '../../http-api';

class SideBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuOpen: false
        }
    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange = (state) => {
        this.setState({
            menuOpen: state.isOpen
        })
    }

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu = () => {
        this.setState({
            menuOpen: false
        })
    }
    
    toggleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    logout = () => {
        localStorage.clear();
        this.props.history.replace('/');
    }
    unsubscrib = () => {
        this.closeMenu();
        console.log('unsucbdhgh');
        Swal({
			title: false,
			html: `<p style="font-size: 14px;text-align: justify;letter-spacing: 1px;line-height: 20px;">
                Are you sure want to unsub?        
            </p>`,
			customClass: 'lets-play-modal',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			confirmButtonColor: 'rgb(65, 113, 156)',
			cancelButtonText: 'No',
			cancelButtonColor: 'rgb(65, 113, 156)',
			confirmButtonClass: 'lets-play-button',
			cancelButtonClass: 'lets-play-button',
			reverseButtons: true,
			focusConfirm: false,
			focusCancel: false,
			allowOutsideClick: false
		}).then((result) => {
			if (result.value) {
                post_loggedInUser('unsubscribe')
                .then(res => {
                    localStorage.setItem('JWT', res.data.JWT);
                    localStorage.setItem('userDetails', JSON.stringify(res.data.menber_details));
                    this.props.history.replace('/lets-play');
                })
                .catch(err => console.log(err));
            }
        });
    }
    render(){
        const language = { "en": "English", "id": "Indonesia", "ms": "Malaysia", "nl": "Deutch" };
        const date = new Date();
        return (
            <div>
                {/* <Account closeMenu={this.closeMenu} />
                <a className="menu-item menu-divider">
                    Menu
                    <span style={{
                        float: 'right',
                        background: '#3c97eb',
                        padding: '0px 10px',
                        lineHeight: '20px',
                        marginTop: '7px',
                        borderRadius: '2px'
                    }}>Premium</span>
                </a>
                <Link onClick={() => this.closeMenu()} to={`/homepage/${this.props.match.params.playType}`} className="menu-item indivdual-item-menu">
                    <i className="fa fa-home"></i><div className="menu-item-div">Home</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to={`/rewards/${this.props.match.params.playType}`} className="menu-item indivdual-item-menu">
                    <i className="fa fa-trophy"></i><div className="menu-item-div">Rewards</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to="/leaderboard" className="menu-item indivdual-item-menu">
                    <i className="fa fa-trophy"></i><div className="menu-item-div">Leader Board</div>
                </Link>
                <Link onClick={() => this.closeMenu()} to={{pathname:"/lang", state: { from: this.props.location.pathname} }} className="menu-item indivdual-item-menu">
                    <i className="fa fa-globe"></i><div className="menu-item-div">Language</div>
                    <div style={{float: 'right', marginRight: '40px'}}>{language[selectedLanguage()]}</div>
                </Link>
                <a onClick={this.unsubscrib} className="menu-item indivdual-item-menu">
                    <i className="fa fa-sign-out-alt"></i><div className="menu-item-div">Unsubcribe</div>
                </a>
                <Link onClick={() => this.closeMenu()} to="/logout" className="menu-item indivdual-item-menu">
                    <i className="fa fa-sign-out-alt"></i><div className="menu-item-div">Logout</div>
                </Link>
                <a className="menu-item menu-divider"></a>
                <Link onClick={() => this.closeMenu()} to="/faq" className="menu-item indivdual-item-menu">
                    <i className="fa fa-question-circle"></i><div className="menu-item-div">FAQ</div>
                </Link> */}
            </div>
        );
    }
};

export default withRouter(SideBar);
