import React, { Component, Fragment } from 'react';
import profile_img from '../../assets/img/acc-default.png';
import { isLoggedIn, userDetails } from '../../helper/authentication';
import { Link, withRouter } from 'react-router-dom';
import gold_coin from '../../assets/gold_coin.png';
import Avatar from 'react-avatar';
import { post_loggedInUser, BASE_URL_PROFILE } from '../../http-api';

class Account extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userinfo: [],
            loading: true
        }
    }
    componentWillUpdate() {
        console.log('Account component will update');
    }
    componentDidMount() {
        const payload = new FormData();
        payload.append('subscribe_val', 'premium')
        post_loggedInUser('playtypeinfo', payload)
        .then(res => {
            console.log(res);
              if(res.data.success) {
                const {userinfo} = res.data;
                this.setState({loading: false, userinfo});
            }
           })
            .catch(err => {
               this.setState({loading: false});
                throw new Error('Could not fetch products. Try again later.');
           });
    }
    render() {
    let { userinfo } =this.state;
    userinfo = Object.assign({}, userDetails(), userinfo);
    // userinfo = userDetails();
    // console.log({userinfo});
    // console
    return(
        <Fragment>
                {
                    userinfo ?
                        <Link onClick={this.props.closeMenu} to="/profile" style={{position: 'relative', display: 'block', padding: '20px 0px 8px 8px'}}>
                            <div style={{overflow: 'hidden'}}>
                                <Avatar style={{float: 'left'}}
                                    round={true} 
                                    size="60" 
                                    src = {
                                        userinfo.image ? `${BASE_URL_PROFILE}${userinfo.image}` : null
                                    }
                                    name = {
                                        `${userinfo.firstname} ${userinfo.lastname}`
                                    }
                                />
                                <p style={{padding: '25px 0 0 10px', display: 'inline-block', color: '#fff'}}>{Boolean(userinfo.firstname == '') && Boolean(userinfo.lastname == '') ? `[No Name]`: userinfo.firstname+' '+userinfo.lastname}</p>
                            </div> 
                                <div style={{position: 'absolute', right: '50px', top: '0px', zIndex: 1000}}>
                                    <img src={gold_coin} style={{height: '40px', position: 'relative', zIndex: 1}}/>
                                    <span class="badge" style={{
                                        fontSize: '14px', 
                                        marginLeft: '-8px',
                                        padding: '5px 7px 5px 8px',
                                        borderRadius:'5px', 
                                        backgroundColor: '#FF4E1A'
                                    }}>{userinfo.coins}</span>
                                </div>
                                {userinfo.hasOwnProperty('token') && <div style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: '10px',
                                    color: '#bdbdbd',
                                    fontSize: '15px',
                                    letterSpacing: '1px',
                                    textTransform: 'uppercase'
                                }}>Token: {userinfo.token}</div>}
                        </Link>
                    :
                        <a onClick={this.props.closeMenu} style={{position: 'relative', display: 'block', padding: '20px 0px 8px 8px'}}>
                            <div>
                                <Avatar style={{float: 'left'}}
                                    round={true} 
                                    size="60" 
                                    src={profile_img} 
                                    name="Demo Azik"
                                />
                                <p style={{padding: '25px 0 0 10px', display: 'inline-block', color: '#fff'}}>Demo Quizy</p>
                            </div> 
                        </a>
                }
        </Fragment>
    );
    }
};

export default withRouter(Account);