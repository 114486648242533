import React, {Component, Fragment} from 'react';
import './share.css';

import { FacebookShareButton, WhatsappShareButton, TwitterShareButton } from 'react-share';
import { FacebookIcon, WhatsappIcon, TwitterIcon } from 'react-share';

class Share extends Component {
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.closeShareComponent();
        }
    }
    render() {
        const origin = window.location.origin;
        // const origin = `http://demo.quizy.mobi`;
        const pathname = window.location.pathname;
        const shareUrl = `${origin}${pathname}`;
        const title = "Quizy";

        return(
            <div className="share-overlay">
                <div ref={this.setWrapperRef} className="share-container">
                    <p style={{color: '#a2a0a0'}}>Share With<span onClick={()=>this.props.closeShareComponent()} className="share-close">&times;</span></p>
                    <div className="Demo__container">
                        <div className="Demo__some-network">
                          <FacebookShareButton
                                onShareWindowClose={this.props.closeShareComponent}
                                url={shareUrl}
                                quote={'title'}
                                className="Demo__some-network__share-button">
                                <FacebookIcon size={40}/>
                            </FacebookShareButton>
                            <span style={{color: '#a2a0a0'}}>Facebook</span>
                        </div>
                        <div className="Demo__some-network">
                            <WhatsappShareButton
                                onShareWindowClose={this.props.closeShareComponent}
                                url={shareUrl}
                                title={title}
                                separator=":: "
                                className="Demo__some-network__share-button"
                            >
                                <WhatsappIcon size={40}/>
                            </WhatsappShareButton>
                            <span style={{color: '#a2a0a0'}}>Whatsapp</span>
                            {/* <div className="Demo__some-network__share-count">
                                &nbsp;
                            </div> */}
                        </div>
                        <div className="Demo__some-network">
                            <TwitterShareButton
                                onShareWindowClose={this.props.closeShareComponent}
                                url={shareUrl}
                                title={title}
                                className="Demo__some-network__share-button">
                                <TwitterIcon size={40}/>
                            </TwitterShareButton>
                            <span style={{color: '#a2a0a0'}}>Twitter</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Share;