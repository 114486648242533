import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import 'prevent-pull-refresh';
var shuffle = require('shuffle-array');
import { withRouter } from 'react-router-dom';
import Loader from '../../components/loader/spiner';
import SubmitLoader from './submit-loader';
import Timer from '../../components/timer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { post } from '../../http-api'; 
import DisableBackButton from '../diasble-back-button';
import { userDetails } from "../../helper/authentication";
import Swal from 'sweetalert2';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import './playing-quiz.css';

class PlayingQuiz extends Component { 
    constructor(props) { 
        super(props);
        this.state = {
            quizSubmitting: false,
            selectedQuestionIndex: 0,
            playedQuestion: [],
            submitLoader: false,
            stopTimer: false
        }
        
    }
    componentDidMount() {
        document.body.classList.add("prevent-pull-refresh"); 
    }
    
    componentWillUnmount() {
        document.body.classList.remove("prevent-pull-refresh");
    }
    
    onChooseOption = option => {
        const { selectedQuestionIndex } = this.state;
        const { questions } = this.props;
        const selectedQuestion = questions[selectedQuestionIndex];
        const option_choose_time = document.getElementById("Progress1").innerHTML;
        
        const playedQuestionData = {
            answer_id: selectedQuestion.answer_id,
            points: selectedQuestion.points,
            option_choose: option,
            option_choose_time
        };
        if (selectedQuestionIndex + 1 === questions.length) {
            // submit quiz
            console.log('submit quiz');
            const { playedQuestion } = this.state;
            playedQuestion.push(playedQuestionData);
            console.log({playedQuestion});
            this.setState({submitLoader: false, stopTimer: true},()=>{
               
            //api call
            if (!this.state.submitLoader) {
                const _self = this;
                const payload = new FormData(); 
                payload.append('playedquiz', JSON.stringify(playedQuestion));
                payload.append('msisdn', userDetails().msisdn);
                post('submitAnsware', payload)
                .then( res => {
                    if(res.data.error===0 && res.data.success===1){
                        _self.props.history.replace(`/results/${res.data.result_id}`);
                        
                    }
                   
                })
                .catch( err => console.log(err));
            }
            });
            
        }
        if (selectedQuestionIndex + 1 < questions.length) {
            // next question
            console.log('next quiz');
            this.setState(prevState => {
                const selectedQuestionIndex = prevState.selectedQuestionIndex + 1;
                const playedQuestion = prevState.playedQuestion;
                playedQuestion.push(playedQuestionData);
                return ({selectedQuestionIndex, playedQuestion});
            
            });
        }
        else{
        }
        
    }

    render() {
        const { questions,timeduration } = this.props;
        const { selectedQuestionIndex, submitLoader, stopTimer } = this.state;
        const selectedQuestion = questions[selectedQuestionIndex];
        if(selectedQuestion===undefined)
            return (<Loader />);
        let buttonColorClass = ['an1', 'an2', 'an3', 'an4'];
        buttonColorClass = shuffle(buttonColorClass);
        // Variable for options
        let optionsDom;
        const options = JSON.parse(selectedQuestion.options);
        optionsDom = Object.entries(options).map((value, key)=>(
            <div key={key} class="">
                <button 
                onClick={()=>this.onChooseOption(value[0])}
                tabindex = "0"
                class = {classNames("vix", buttonColorClass[key])}
                type = "button">
                    <span class=""><strong>{value[0]}:&nbsp;</strong>&nbsp;{value[1]}</span>
                    <span class=""></span>
                </button>
            </div>
        ));
        const base_url = `http://demo.quizy.mobi/assets/uploads/logo/`;

        let quiz_image = null;
        if(selectedQuestion.quiz_image)
            quiz_image = (
                <LazyLoadImage
                    effect="blur"
                    height="200px"
                    src={base_url+selectedQuestion.quiz_image}
                />
            );
        return(
                <React.Fragment>
                <DisableBackButton />
                <div class="pd-15">
                    <div class="col-xs-12 no-padding" style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', alignItems: 'center', marginBottom: '5px'}}>
                        <p style={{fontSize: '20px', alignSelf: 'flex-end'}}><span style={{backgroundColor: 'lightgrey', padding: '3px 10px', borderRadius: '25px', color: 'grey', fontStyle: 'italic'}}>{selectedQuestionIndex+1} of {questions.length}</span></p>
                        <Timer stopTimer={stopTimer} start={0} onTimeUp={this.onChooseOption} timeduration={timeduration}/>
                    </div>
                    <div class="clearfix"></div>
                    <TransitionGroup style={{position: 'relative'}}>
                        <CSSTransition
                            key={selectedQuestion.id}
                            timeout={3000}
                            classNames="slide"
                        >
                            <div style={{position: 'absolute', width: '100%'}}>
                                <div class="quiz-head">
                                    <div class="" style={{marginTop: '1px', textAlign: 'center'}}>
                                        {quiz_image}
                                    </div>
                                </div>
                                <div class="quiz-q mt-10">
                                    <div class="col-xs-12 no-padding" style={{backgroundColor: '#fff', padding: '0px 20px', textAlign: 'justify',borderRadius: '5px', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 5px'}}>
                                        <p>{selectedQuestion.title}</p>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="quiz-an">
                                    {optionsDom}
                                </div>
                            </div> 
                        </CSSTransition>
                    </TransitionGroup>
			        <div class="clearfix"></div>
		        </div>
                {submitLoader && <SubmitLoader msg="while your Quiz is submitted"/>}
                </React.Fragment>
                
        );
    }
};


export default withRouter(PlayingQuiz);

const timestampFormat = (time) => {
    const min = Math.floor(time / (60 * 1000));
    time = (time - min * (60 * 1000));
    const sec = Math.floor(time / 1000);
    const milisec = time - sec * 1000;
    return ` ${min>9? min: '0'+min}<sub>m</sub> ${sec>9? sec: '0'+sec}<sub>s</sub> ${milisec>9? parseInt(milisec).toFixed(0): '0'+parseInt(milisec).toFixed(0)}<sub>ms</sub>`;
}