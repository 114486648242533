import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TemplateWithSlideBar from "../../template/template-with-slide-bar";
import Avatar from "react-avatar";
import { post } from "../../http-api";
import { userDetails } from "../../helper/authentication";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./result.css";
import { BASE_URL_PROFILE } from "../../http-api";
import shareIcon from "../../assets/share.png";
import Share from "../../components/share";
import { NoDataFound } from "../no-data-found";

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: {},
      noDataFound: false,
      shareComponent: false,
    };
  }
  openShareComponent = () => {
    this.setState({ shareComponent: true });
    document.getElementById("pageContent").classList.add("overflow-hidden");
  };
  closeShareComponent = () => {
    this.setState({ shareComponent: false });
    document.getElementById("pageContent").classList.remove("overflow-hidden");
  };
  componentDidMount() {
    this.getResultById(this.props.match.params.id);
  }
  getResultById = (id) => {
    const resultId = this.props.match.params.id;
    const payload = new FormData();
    payload.append("result_id", resultId);
    post("resultDisplay", payload)
      .then((res) => {
        if (res.data.success) {
          let { result } = res.data;
          this.setState({ result: result, noDataFound: false });
          payload.append("user_id", userDetails().user_id);
          payload.append("msisdn", userDetails().msisdn);
          post("removeSmsToken", payload).then((res) => {
            if (res.data.success) {
              localStorage.removeItem("userDetails");
            } else {
            }
          });
        }
        if (res.data.error) {
          this.setState({ noDataFound: true });
        }
      })
      .catch((err) => {
        this.setState({ noDataFound: true });
        throw new Error("Could not fetch products. Try again later.");
      });
  };

  timeFormat = (time) => {
    const min = Math.floor(time / 60);
    const sec = time - min * 60;
    return `  ${min}m ${sec.toFixed(3)}s`;
  };

  render() {
    const { result, noDataFound } = this.state;

    return (
      <Fragment>
        <TemplateWithSlideBar>
          <div className="block-title">
            <h3>Quiz Result</h3>
          </div>
          {noDataFound ? (
            <NoDataFound />
          ) : (
            <Fragment>
              <div className="pd-15">
                <div className="quiz-info">
                  <h5>
                    <span className="pull-right">
                      <i className="fa fa-clock"></i>{" "}
                      {this.timeFormat(result.total_time)}
                    </span>
                  </h5>
                </div>
                <div className="set">
                  <div className="set-img">
                    {result && (
                      <Avatar
                        round={true}
                        size="140"
                        src={
                          result.image
                            ? `${BASE_URL_PROFILE + result.image}`
                            : null
                        }
                        name={"Quizy"}
                      />
                    )}
                  </div>
                  <div className="set-bar" style={{ marginRight: "16px" }}>
                    <div className="set-extra">
                      <h5 className="desc1">
                        Right Answer: <span>{result.right}</span>
                      </h5>
                    </div>
                    <div className="set-extra">
                      <h5 className="desc2">
                        Wrong Answer: <span>{result.wrong}</span>
                      </h5>
                    </div>

                    <div className="set-extra">
                      <h5 className="desc1">
                        Accurate:{" "}
                        <span>{parseFloat(result.accurate).toFixed(2)}%</span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    marginTop: "39px",
                    background: "#128bb4",
                    padding: "15px",
                    borderRadius: "10px",
                    color: "#fff",
                    letterSpacing: "0.5px",
                    fontSize: "20px",
                  }}
                >
                  Total Score:<span>&nbsp;{parseInt(result.score)}</span>
                </div>
              </div>
              <div className="pd-15">
                <div
                  className="quiz-info"
                  style={{ padding: "2px 15px" }}
                ></div>
              </div>
              <div className="clearfix"></div>
              <div class="pd-15 text-center">
                <Link to={`/`} class="btn btn-primary btn-round">
                  Back to Home
                </Link>
              </div>
              <div class="clearfix"></div>
            </Fragment>
          )}
        </TemplateWithSlideBar>
      </Fragment>
    );
  }
}

export default withRouter(Result);

const ResultDetails = (props) => {
  return <h1>Result Details</h1>;
};
