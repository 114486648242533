import axios from 'axios';

// const BASE_URL = window.location.origin;

// const BASE_URL = 'http://indosat.quizy.mobi';
// const BASE_URL = 'http://sf.quizy.mobi';
const BASE_URL = 'https://playfun.quizy.mobi';

//ImageDirPath
export const BASE_URL_IMAGE = `${BASE_URL}/assets/uploads/logo/`;
export const BASE_URL_PROFILE = `${BASE_URL}/assets/uploads/profiles/`;
export const BASE_URL_REACT_QUIZY_LOGO = `${BASE_URL}/assets/react/logo/logo.png`;
// X-API-KEY for API call
const X_API_KEY = 'CODEX@123';

// Authorised AdminID and Password
const AUTH_ADMIN_ID = 'admin';
const AUTH_PASSWORD = '1234'; 

 // Basic Auth
const BASIC_AUTH = `Basic ${window.btoa(`${AUTH_ADMIN_ID}:${AUTH_PASSWORD}`)}`;

// Post Request After login
export const post_loggedInUser = (uri, payload = null) => {
    return axios({
        "async": true,
        "crossDomain": true,
        "url": `${BASE_URL}/api/${uri}`,
        "method": "POST",
        "headers": {
            "Authorization": BASIC_AUTH,
            "X-API-KEY": X_API_KEY,
            "jwt": localStorage.getItem('JWT'),
            "cache-control": "no-cache",
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": payload
    });
}

export const authPost = (uri, payload = null) => {
    return axios({
        "async": true,
        "crossDomain": true,
        "url": `${BASE_URL}/api/${uri}`,
        "method": "POST",
        "headers": {
            "Authorization": BASIC_AUTH,
            "X-API-KEY": X_API_KEY,
            "jwt": localStorage.getItem('JWT'),
            "cache-control": "no-cache",
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": payload
    });
}
// Post Request After login
export const post = (uri, payload = null) => {
    return axios({
        "async": true,
        "crossDomain": true,
        "url": `${BASE_URL}/api/${uri}`,
        "method": "POST",
        "headers": {
            "Authorization": BASIC_AUTH,
            "X-API-KEY": X_API_KEY,
            "cache-control": "no-cache",
        },
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": payload
    });
}