import React, {Component, Fragment} from 'react';


import { withRouter } from 'react-router-dom';
import closeIcon from '../../assets/close-icon.png';

import QuizPlayTemplate from './components/QuizPlayTemplate';
import { userDetails } from '../../helper/authentication';
import {Animated} from "react-animated-css";
import PlayingQuiz from './playing-quiz'; 
import { post } from '../../http-api';

import Swal from 'sweetalert2';
import './quiz-details.css';
import {Modal,Button} from 'react-bootstrap';

import QuizDetailsComponent from './QuizDetails';

import { userDetails as loggedInUserDetails } from "../../helper/authentication";

class QuizDetails extends Component {

     
    constructor(props) {
        super(props);
        this.state = {
            playQuiz: false,
            questionList: [],
            timeduration:null,
            modalOpen : false,
            msisdnNumber : '',
            errorMsisdn : false ,
            apiFeedBack : '', 
            regexp : /^[0-9\b]+$/        
        }
        
    }

    componentDidMount(){

        console.log(localStorage.getItem("userDetails"));

        //if(JSON.parse(localStorage.getItem("userDetails")).status == 'yes');

    }

    onMsisdnChange =(e)=>{

      if(this.state.regexp.test(e.target.value)){

        this.setState({msisdnNumber : e.target.value})
      }else{
        this.setState({msisdnNumber : ''})
      }
      


    }

    msisdnSubmit =()=>{

       


        if(this.state.msisdnNumber == ''){
           this.setState({errorMsisdn:true})
        }else{
            const payload={
                'msisdn':this.state.msisdnNumber
            }
            post('getRealMsisdn', payload)
            .then( res => {
                if (res.data.success == 1) {
                    //loggedInUserDetails().msisdn_number = '859641'
                    //console.log('=================='+loggedInUserDetails().msisdn_number);
                    //localStorage.setItem('msisdn_number', '899666');

                    this.setState({modalOpen:false});

                }
                if (res.data.success == 0) {
                    this.setState({apiFeedBack: res.data.message});
                }
                this.setState({loadingRegistration: false});
            })
            .catch(error=> {
                console.log(error);
                this.setState({loadingRegistration: false});
            });

            this.setState({errorMsisdn:false})
        }

    }

    handleClose =()=> {

        this.setState({modalOpen: false})
    }


    playQuiz = () => {
        this.setState({playQuiz: true});
    }

    setQuestionList = (questionList,timeduration) => {
        this.setState({questionList, timeduration,playQuiz: true});
    }

    quitQuiz = () => {
        Swal({
            title: 'Are you sure? For this action you will be deactivated and go to Homepage.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                // this.setState({playQuiz: false});
                const payload = new FormData();
                payload.append('user_id', userDetails().user_id);
                payload.append('msisdn', userDetails().msisdn);
                post('removeSmsToken',payload)
                .then(res=>{
                    if(res.data.success){
                        localStorage.removeItem('userDetails');
                        this.props.history.push('/')
                    }
                    else{
                    }
                    
                })
            }
        })   
    }

    render() {
        const { playQuiz, questionList,timeduration} = this.state;
        console.log('quizPlay', this.state);
        return(
            <Fragment>

                    


                    <QuizDetailsComponent setQuestionList={this.setQuestionList}/>
                    {playQuiz && <Animated style={{position: 'absolute',right: 0,left: 0,top: 0,zIndex: 9999}} animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={playQuiz}>
                         <QuizPlayTemplate>
                        <div class="block-title"><h3>Quiz Play</h3></div>
                            <PlayingQuiz questions={questionList} timeduration={timeduration}/>
                            <div class="clearfix"></div>
                            <div style={
                            {
                                position: 'fixed', 
                                bottom: '-60px',
                                right: '-60px',
                                color: '#fff',
                                width: '100px',
                                height: '100px',
                                borderRadius: '36%',
                                backgroundColor: '#8784a1',
                                cursor: 'pointer',
                                lineHeight: '45px',
                                paddingLeft: '18px',
                            }
                        }>
                            <img onClick={this.quitQuiz} src={closeIcon} />
                        </div>
                    </QuizPlayTemplate>
                    </Animated>
                    }
                    
                  
                    
                     



            </Fragment> 

        );
    }
};

export default withRouter(QuizDetails);


