import React, { Fragment } from 'react';
import logo from '../../../assets/img/logo.png';

const QuizPlayTemplate = ({children}) => (
    <div>
        <div id="toolbar" className="bluedark toporange botorange">
            <div className="open-left"></div>
            <div className="logo m-0">
                <a><img src={logo} alt="" style={{height: '42px'}}/></a>
            </div>
            <div className="open-right" data-activates="slide-out-right"></div>
        </div>
        <div className="clearfix"></div>
        <div className="page-content page-content-bg-color" style={{height: '100vh', overflow: 'hidden'}}>{children}</div>
    </div>
);

export default QuizPlayTemplate;